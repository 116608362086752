<template>
  <div>
    <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
    <table :class="`w-100 table--perweek table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <tr>
          <th class="table__head--text">Naam</th>
          <th class="table__head--text">Srt</th>
          <th class="table__head--text">St</th>
          <th class="table__head--text">Item</th>
          <th class="table__head--text">Voertuig</th>
          <th class="table__head--text">Verwacht</th>
          <th class="table__head--text">Bet.</th>
          <th class="table__head--text">Bet. op</th>
          <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__head--text">
            Leverancier
          </th>
          <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__head--text">
            Locatie
          </th>
          <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__head--text">
            Inkoopprijs
          </th>
          <th class="table__head--text">Transporteur</th>
          <th class="table__head--text table__data--right">Kosten</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
          <td class="table__cell--default">
            {{ item.naam }}
          </td>
          <td class="table__cell--default">
            <div v-if="item.soortnaam == 'halen'">
              <span v-b-tooltip.hover title="Halen"><i class="fas fa-arrow-left"></i></span>
            </div>
            <div v-else>
              <span v-b-tooltip.hover title="Wordt gebracht"><i class="fas fa-arrow-right"></i></span>
            </div>
          </td>
          <td class="table__cell--default">
            <div v-if="item.statusnaam == 'nieuw'">
              <span class="dot dot--red" v-b-tooltip.hover title="Ingepland"></span>
            </div>
            <div v-else>
              <span class="dot dot--blue" v-b-tooltip.hover title="Nieuw"></span>
            </div>
          </td>
          <td class="table__cell--default">
            <router-link :to="{
              name: 'ItemPage',
              params: { id: item.itemnummer, bu: item.bu },
            }">{{ item.itemnummer }}</router-link>
          </td>
          <td class="table__cell--default">{{ item.voertuig }}</td>
          <td class="table__cell--default">{{ item.aanleverdatum }}</td>
          <td class="table__cell--default">
            <div v-if="item.betaald == 1">
              <span v-b-tooltip.hover :title="item.betaaldatum"><i class="fas fa-euro-sign"></i></span>
            </div>
            <div v-else>
              <span v-b-tooltip.hover title="Nog niet betaald"><i class="fab fa-creative-commons-nc-eu"></i></span>
            </div>
          </td>
          <td class="table__cell-default">{{ item.betaaldatum }}</td>
          <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__cell--default">
            {{ item.bedrijf }}
          </td>
          <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            class="table__cell--default">
            {{ item.locatie }}
          </td>
          <td class="table__cell--default  table__data--right">€ {{ item.inkoopprijs }}</td>
          <td class="table__cell--default">{{ item.transporteur }}</td>
          <td class="table__cell--default table__data--right">
            € {{ item.kosten }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  props: {
    table_data: [Object, Array],
    table_style: String,
    name: [String]
  },
  methods: {
    createExcel() {
      let sales_orders = [];
      this.table_data.forEach((i) => {
        sales_orders.push({
          "Naam": i.naam,
          "Soort": i.soortnaam,
          "Status": i.statusnaam,
          "Itemnummer": i.itemnummer,
          "Voertuig": i.voertuig,
          "Verwacht": i.aanleverdatum,
          "Betaald op:": this.moment(i.betaaldatum, 'MM-DD-YYYY'),
          "Leverancier": i.bedrijf,
          "Locatie": i.locatie,
          "Transporteur": i.transporteur,
          "Kosten": i.kosten,
        });
      });

      var telWS = XLSX.utils.json_to_sheet(sales_orders);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `${this.name}`
      );
      XLSX.writeFile(
        wb,
        `Openstaande_inkooporders_${this.name}.xlsx`
      );
    },
  }
};
</script>
